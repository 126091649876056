import React from 'react';
import styled from 'styled-components';
import { Layout } from '../components/Layout';
import { InlineLink } from '../elements/InlineLink';
import COLORS from '../utils/colors';

const Contact = () => {
  return (
    <Layout>
      <h1>Contact Info</h1>
      <p>
        I am not the most active on social media, but you can find me on Twitter{' '}
        <InlineLink to='https://twitter.com/jamestheyounger'>
          @JamesTheYounger
        </InlineLink>{' '}
        (it's all just retweeted basketball memes) or on{' '}
        <InlineLink to='https://www.linkedin.com/in/jim-griffith-ia/'>
          LinkedIn
        </InlineLink>
        . You can also email me at{' '}
        <StyledEmail href='mailto:jim@sheepsort.com'>
          jim@sheepsort.com
        </StyledEmail>
        .
      </p>
    </Layout>
  );
};

export default Contact;

const StyledEmail = styled.a`
  text-decoration: none;
  color: ${COLORS.blue};
`;
