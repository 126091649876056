import { Link } from 'gatsby';
import React from 'react';
import COLORS from '../utils/colors';

export const InlineLink = ({ children, style, ...props }) => (
  <Link
    target='_blank'
    rel='noreferrer'
    style={{ textDecoration: 'none', color: COLORS.blue, ...style }}
    {...props}
  >
    {children}
  </Link>
);
